import { useEffect, useMemo, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import {
  Link,
  useLocation,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { useGetCategoryArticles, useGetSearchArticle } from "../service/index";
import config from "../config/config";

// components
import CategoryListLg from "./category-list";
import DataNotFound from "./global-components/data-not-found";
import CategoryListSm from "./category-list-sm";
import Pagination from "./global-components/pagination";
import ArticleArchiveSkelecton from "./articles-archive-skeleton";

const Articles = () => {
  const { slug } = useParams();
  const [searchParams] = useSearchParams();
  const { state } = useLocation();

  const initialSearchKeyword = searchParams.get("search") || "";
  const [searchKeyword, setSearchKeyword] = useState(initialSearchKeyword);
  const [articles, setArticles] = useState<any[]>([]);
  const [categoryDetails, setCategoryDetails] = useState<any>();
  const [page, setPage] = useState(1);
  const [disablePrev, setDisablePrev] = useState(false);
  const [disableNext, setDisableNext] = useState(false);
  const [totalPages, setTotalPages] = useState(1);
  const [isSinglePageData, setIsSinglePageData] = useState(false);

  const {
    data: categoryArticles,
    isLoading: isLoadingCategoryArticle,
    isFetching: isFetechingCategoryArticle,
    refetch: refetchCategoryArticle,
  } = useGetCategoryArticles(slug, page);

  const {
    data: searchedArticles,
    isFetching: isFetchingSearchedArticle,
    refetch: refetchSearchArticle,
  } = useGetSearchArticle(searchKeyword, page);

  useEffect(() => {
    if (categoryArticles) {
      setArticles(categoryArticles.data.data);
      setCategoryDetails(categoryArticles.cat_details);
      setDisablePrev(categoryArticles.data.current_page === 1);
      setDisableNext(categoryArticles.data.last_page === page);
      setTotalPages(categoryArticles.data.last_page);
      setIsSinglePageData(
        categoryArticles.data.total <= categoryArticles.data.per_page
      );
    }
  }, [categoryArticles, page]);

  useEffect(() => {
    if (searchedArticles && searchKeyword) {
      setArticles(searchedArticles.data.data);
      setDisablePrev(searchedArticles.data.current_page === 1);
      setDisableNext(searchedArticles.data.last_page === page);
      setTotalPages(searchedArticles.data.last_page);
    }
  }, [searchedArticles, searchKeyword, page]);

  useEffect(() => {
    if (!state?.searchedArticleList && searchKeyword) {
      refetchSearchArticle();
    }
  }, [searchKeyword, refetchSearchArticle, state?.searchedArticleList, page]);

  useEffect(() => {
    if (state?.searchedArticleList) {
      setArticles(state?.searchedArticleList);
      setSearchKeyword(initialSearchKeyword);
    }
  }, [state?.searchedArticleList, initialSearchKeyword]);

  useEffect(() => {
    if (slug) {
      refetchCategoryArticle();
    }
  }, [slug, refetchCategoryArticle, page]);

  const articleHeading = useMemo(() => {
    if (searchKeyword) {
      return `Keyword: ${searchKeyword}`;
    }
    return categoryDetails?.name;
  }, [searchKeyword, categoryDetails]);

  return (
    <div>
      <CategoryListSm
        onClick={() => setSearchKeyword("")}
        selectedCategorySlug={slug ? slug : ""}
      />
      {isLoadingCategoryArticle ? (
        <div className="skeleton-article-title-container"></div>
      ) : (
        <Container fluid>
          <Row>
            <Col sm="12" className="flex-wrap px-0">
              {categoryDetails && (
                <img
                  className="category-heading-img"
                  src={
                    categoryDetails?.category_image !== ""
                      ? config.blogImageURL + categoryDetails?.category_image
                      : "/assets/imgs/other/page-title.webp"
                  }
                  width={"100%"}
                  alt={categoryDetails?.name}
                />
              )}
            </Col>
          </Row>
        </Container>
      )}
      <Container fluid className="article-archive-wrapper">
        <Row>
          <Col>
            <h1 className="article-archive-heading text-uppercase">
              {isLoadingCategoryArticle ? (
                <div className="skeleton-artilce-cat-title"></div>
              ) : (
                articleHeading
              )}
            </h1>
          </Col>
        </Row>
        <Row>
          <CategoryListLg
            onClick={() => setSearchKeyword("")}
            selectedCategorySlug={slug ? slug : ""}
          />
          <Col sm={12} lg={9} className="px-0">
            {isLoadingCategoryArticle ||
            isFetechingCategoryArticle ||
            isFetchingSearchedArticle ? (
              <ArticleArchiveSkelecton />
            ) : articles?.length ? (
              <div className="archive-main-content-wrapper">
                <div className="archive-main-content">
                  {articles.map((article: any, index) => (
                    <Link
                      to={`/article-detail/${slug}/${article.slug}`}
                      state={{ id: article.id }}
                      className="article-grid-container"
                      key={index}
                    >
                      <div className="article-grid-container-img">
                        {article?.feature_img_home === "" ? (
                          <iframe
                            height={"100%"}
                            width={"100%"}
                            src={`${article?.feature_video_url}&autoplay=1&mute=1&controls=0`}
                            title={article?.title}
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            referrerPolicy="strict-origin-when-cross-origin"
                          ></iframe>
                        ) : (
                          <img
                            src={config.blogImageURL + article.feature_img_home}
                            alt={article.title}
                          />
                        )}
                      </div>
                      <h3 className="article-heading text-uppercase">
                        {article.title}
                      </h3>
                    </Link>
                  ))}
                </div>
                {!isSinglePageData && (
                  <Pagination
                    page={page}
                    setPage={setPage}
                    totalPages={totalPages}
                    disablePrev={disablePrev}
                    disableNext={disableNext}
                  />
                )}
              </div>
            ) : (
              <DataNotFound />
            )}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Articles;
