import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  PinterestIcon,
  PinterestShareButton,
  TwitterShareButton,
  XIcon,
} from "react-share";
import config from "../../config/config";

interface Props {
  title: string | undefined;
  media: string | undefined;
}

const ShareIcons: React.FC<Props> = ({ title, media }) => {
  let url = window.location.href;
  return (
    <div className="d-flex flex-row icon-wrapper">
      <EmailShareButton url={url} subject={title} className="share-social-icon">
        <EmailIcon size={24} round />
      </EmailShareButton>
      <FacebookShareButton url={url} className="share-social-icon">
        <FacebookIcon size={24} round />
      </FacebookShareButton>
      <PinterestShareButton
        url={url}
        media={config.blogImageURL + media}
        description={title}
        className="share-social-icon"
      >
        <PinterestIcon size={24} round />
      </PinterestShareButton>
      <TwitterShareButton url={url} title={title} className="share-social-icon">
        <XIcon size={24} round />
      </TwitterShareButton>
    </div>
  );
};

export default ShareIcons;
