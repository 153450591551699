import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-multi-carousel/lib/styles.css";

// pages
import Home from "./components/home";
import Layout from "./components/layout";
import ArticleDetail from "./components/article-detail";
import Articles from "./components/articles";

// components
import ScrollToTop from "./components/global-components/scoll-to-top";

const App = () => {
  return (
    <BrowserRouter basename="/">
      <ScrollToTop />
      <Routes>
        {/* <Switch> */}
        <Route
          element={
            <div>
              {" "}
              <Layout />{" "}
            </div>
          }
        >
          <Route path="/" element={<Home />} />
          <Route path="/articles" element={<Articles />} />
          <Route path="/articles" element={<Articles />} />
          <Route path="/articles/:slug" element={<Articles />} />
          <Route
            path="/article-detail/:category-slug/:slug"
            element={<ArticleDetail />}
          />
          {/* <Route path="*" component={Error} /> */}
          {/* </Switch> */}
        </Route>
      </Routes>
      <ToastContainer
        autoClose={5000}
        hideProgressBar={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        pauseOnHover
        theme="dark"
      />
    </BrowserRouter>
  );
};

export default App;
