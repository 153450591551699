import { useMutation, useQuery } from "react-query";
import apiClient from "../config/axios";
import { queryClient } from "../config/react-query";

interface SubscribeToNewsletterData {
  subscribed_emails: string;
}

// GET article category
const getArticleCategory = async () => {
  const result = await apiClient.get("api/get_article_category");
  return result.data;
};

export const useGetArticleCategory = () => {
  return useQuery({
    queryKey: ["article-category"],
    queryFn: () => getArticleCategory(),
    keepPreviousData: true,
  });
};

// GET article based on category slug
const getCategoryArticles = async (slug: string | undefined, page: number) => {
  const result = await apiClient.get(
    `api/cat_article_list/${slug}?page=${page}`
  );
  return result.data;
};

export const useGetCategoryArticles = (
  slug: string | undefined,
  page: number
) => {
  return useQuery({
    queryKey: ["article-details", slug],
    queryFn: () => getCategoryArticles(slug, page),
    keepPreviousData: true,
    enabled: false,
  });
};

// GET menus details
const getMenus = async () => {
  const result = await apiClient.get("api/fetch_home_details");
  return result.data;
};

export const useGetMenus = () => {
  return useQuery({
    queryKey: ["menus"],
    queryFn: () => getMenus(),
    keepPreviousData: true,
  });
};

// GET article deatils based on slug
const getArticleDetails = async (slug: string | undefined) => {
  const result = await apiClient.get(`api/article_details/${slug}`);
  return result.data;
};

export const useGetArticleDetails = (slug: string | undefined) => {
  return useQuery({
    queryKey: ["article-details", slug],
    queryFn: () => getArticleDetails(slug),
    keepPreviousData: true,
    enabled: false,
  });
};

// GET home page articles
const getHomepageArticles = async (page: number, limit: number) => {
  const result = await apiClient.get(
    `api/fetch_home_listings?page=${page}&limit=${limit}`
  );
  return result.data;
};

export const useGetHomepageArticles = (page: number, limit: number) => {
  return useQuery({
    queryKey: ["homepage-articles", page, limit],
    queryFn: () => getHomepageArticles(page, limit),
    keepPreviousData: true,
  });
};

// GET homepage banner
const getHomepageBanner = async () => {
  const result = await apiClient.get(`api/fetch_banner`);
  return result.data;
};

export const useGetHomePageBanner = () => {
  return useQuery({
    queryKey: ["homepage-banner"],
    queryFn: () => getHomepageBanner(),
    keepPreviousData: true,
  });
};

// Search Article
const getSearchArticle = async (keyword: string | null, page: number) => {
  const result = await apiClient.post(`api/searchArticle`, {
    keyword: keyword,
  });
  return result.data;
};

export const useGetSearchArticle = (keyword: string | null, page: number) => {
  return useQuery({
    queryKey: ["search-article", keyword],
    queryFn: () => getSearchArticle(keyword, page),
    keepPreviousData: true,
    enabled: false,
  });
};

// Subscribe newsletter
const subscribeToNewsletter = async (data: SubscribeToNewsletterData) => {
  return await apiClient.post("api/subscribe", data);
};

export const useSubscribeToNewsletter = () => {
  return useMutation({
    mutationFn: (data: SubscribeToNewsletterData) =>
      subscribeToNewsletter(data),
  });
};

// GET popup content
const getPopupDetail = async () => {
  const result = await apiClient.get("api/popup");
  return result.data;
};

export const useGetPopupDetail = () => {
  return useQuery({
    queryKey: ["popup-detail"],
    queryFn: () => getPopupDetail(),
    keepPreviousData: true,
  });
};

// GET blog comments
const getBlogComments = async (id: string | undefined) => {
  const result = await apiClient.get(`/api/comments/${id}`);
  return result.data;
};

export const useGetBlogComments = (id: string | undefined) => {
  return useQuery({
    queryKey: ["blog-comments", id],
    queryFn: () => getBlogComments(id),
    keepPreviousData: true,
    enabled: false,
  });
};

interface addBlogCommentsData {
  message: string;
  post_id: number;
  name: string;
  email: string;
  // subject: string;
}

interface replyBlogCommentsData {
  message: string;
  post_id: number;
  parent_id: number | string;
  name: string;
  email: string;
  // subject: string;
}

// POST blog comments
const addBlogComments = async (data: addBlogCommentsData) => {
  return await apiClient.post(`api/comments/add`, data);
};

export const useAddBlogComments = () => {
  return useMutation({
    mutationFn: (data: addBlogCommentsData) => addBlogComments(data),
    onSuccess: () => {
      queryClient.invalidateQueries(["blog-comments"]);
      queryClient.refetchQueries(["blog-comments"]);
    },
  });
};

// reply blog comments
const replyBlogComments = async (data: replyBlogCommentsData) => {
  return await apiClient.post(`api/reply/add`, data);
};

export const useReplyBlogComments = () => {
  return useMutation({
    mutationFn: (data: replyBlogCommentsData) => replyBlogComments(data),
    onSuccess: () => {
      queryClient.invalidateQueries(["blog-comments"]);
      queryClient.refetchQueries(["blog-comments"]);
    },
  });
};

// GET SUBSCRIBE SECTION CONTENT
const getSubscribeSectionDetail = async () => {
  const result = await apiClient.get("api/footer");
  return result.data;
};

export const useGetSubscribeSectionDetail = () => {
  return useQuery({
    queryKey: ["subscribe-section-detail"],
    queryFn: () => getSubscribeSectionDetail(),
    keepPreviousData: true,
  });
};
