import { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useSubscribeToNewsletter } from "../../service/index";
import config from "../../config/config";
// import { toast } from "react-toastify";

type SubscribeSectionData = {
  id: number;
  img_url: string;
  title: string;
  note1: string;
  note2: string;
  created_at: string | null;
  updated_at: string;
};

interface SubscribeToNewsletterData {
  subscribed_emails: string;
}

const UpperFooter = ({
  subscribeSectionData,
}: {
  subscribeSectionData: SubscribeSectionData;
}) => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");

  const { mutate } = useSubscribeToNewsletter();

  const validateEmail = (email: string) => {
    if (!email.trim()) {
      return "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      return "Email is invalid";
    }
  };

  const handleChange = (e: any) => {
    setEmail(e.target.value);
    const validate = validateEmail(email);
    if (validate) {
      setError(validate);
    } else {
      setError("");
    }
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    const validate = validateEmail(email);
    if (validate) {
      setError(validate);
    } else {
      setError("");
      const data: SubscribeToNewsletterData = {
        subscribed_emails: email,
      };
      mutate(data, {
        onSuccess: async () => {
          setEmail("");
          // toast.dismiss();
          // toast.success(
          //   subscribeSectionData.find((item) => item.id == 11)?.entity_link
          // );
        },
      });
    }
  };

  return (
    <Container fluid>
      <Row className="upper-footer-container justify-content-center align-items-center">
        <Col xs={12} lg={6} className="px-0">
          {/* <div
            dangerouslySetInnerHTML={{ __html: subscribeSectionData.find((item) => item.id === 9)?.entity_link }}
          /> */}
          <img
            className="upper-footer-img"
            src={config.popupImageURL + subscribeSectionData?.img_url}
            width={"100%"}
            alt="upper-footer-nour"
          />
        </Col>
        <Col xs={12} lg={6} className="text-center">
          <h2>{subscribeSectionData?.title}</h2>
          <div
            dangerouslySetInnerHTML={{
              __html: subscribeSectionData?.note2,
            }}
          />
          <form onSubmit={(e) => handleSubmit(e)}>
            <label>
              {/* Name: */}
              <input
                type="email"
                value={email}
                onChange={(e) => handleChange(e)}
                placeholder="Email address"
              />
            </label>
            {error && <p className="text-danger text-start">{error}</p>}
            <input type="submit" value="Submit" />
          </form>

          {/* <div
            dangerouslySetInnerHTML={{
              __html: subscribeSectionData?.note1,
            }}
          /> */}
        </Col>
      </Row>
    </Container>
  );
};

export default UpperFooter;
