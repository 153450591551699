import { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { useGetMenus, useGetSubscribeSectionDetail } from "../service/index";
import "bootstrap/dist/css/bootstrap.css";

// components
import Header from "./global-components/header";
import Footer from "./global-components/footer";
import UpperFooter from "./global-components/upper-footer";
interface TopbarContent {
  id: number;
  entity_name: string;
  entity_data: string;
  entity_link: string;
  created_at: string;
  updated_at: string;
}

interface Menu {
  id: number;
  title: string;
  url: string;
}

interface MenuList {
  id: number;
  name: string;
  menus: {
    id: number;
    menu_categories: number;
    title: string;
    url: string;
    sort_id: number;
    created_at: string;
    updated_at: string;
  }[];
  created_at: string;
  updated_at: string;
}

interface Entity {
  id: number;
  entity_name: string;
  entity_data: string;
  entity_link: string;
  created_at: string | null;
  updated_at: string | null;
}

type SubscribeSectionData = {
  id: number;
  img_url: string;
  title: string;
  note1: string;
  note2: string;
  created_at: string | null;
  updated_at: string;
};

const Layout = () => {
  const { data } = useGetMenus();
  const { data: subscribeSectionDetails } = useGetSubscribeSectionDetail();

  const [headerMenu, setHeaderMenu] = useState<Menu[]>([]);
  const [topbarContent, setTopbarContent] = useState<TopbarContent[]>([]);
  const [headerNavLink, setHeaderNavLink] = useState<Entity[]>([]);
  const [footerMenu, setFooterMenu] = useState<MenuList[]>([]);
  const [socialLinks, setSocialLinks] = useState<Entity[]>([]);
  const [subscribeSectionData, setSubscribeSectionData] =
    useState<SubscribeSectionData>({
      id: 0,
      img_url: "",
      title: "",
      note1: "",
      note2: "",
      created_at: "",
      updated_at: "",
    });
  const [copyrightContent, setCopyrightContent] = useState<Entity | null>(null);
  const [cookieExpiration, setCookieExpiration] = useState<Entity | null>(null);

  useEffect(() => {
    if (data) {
      setHeaderNavLink(
        data.data.OtherEntityData.filter(
          (link: Entity) => link.id === 15 || link.id === 16 || link.id === 17
        )
      );

      setTopbarContent(
        data.data.OtherEntityData.filter((link: Entity) => link.id === 1)
      );

      setCopyrightContent(
        data.data.OtherEntityData.find((link: Entity) => link.id === 13)
      );

      setCookieExpiration(
        data.data.OtherEntityData.find((link: Entity) => link.id === 18)
      );

      const sortedMenus = data.data.Menus.map((menu: MenuList) => ({
        ...menu,
        menus: menu.menus.sort((a: any, b: any) => a.sort_id - b.sort_id),
      }));

      setHeaderMenu(sortedMenus[0].menus);
      setFooterMenu([sortedMenus[1], sortedMenus[2], sortedMenus[3]]);
      setSocialLinks(
        data.data.OtherEntityData.filter(
          (link: Entity) =>
            link.id === 2 ||
            link.id === 3 ||
            link.id === 4 ||
            link.id === 5 ||
            link.id === 6 ||
            link.id === 7 ||
            link.id === 8
        )
      );
    }
  }, [data]);

  useEffect(() => {
    if (subscribeSectionDetails) {
      setSubscribeSectionData(subscribeSectionDetails.data);
    }
  }, [subscribeSectionDetails]);

  return (
    <div>
      <Header
        menu={headerMenu}
        navbarLink={headerNavLink}
        topbarContent={topbarContent}
        cookieExpiration={cookieExpiration}
      />
      <main>
        <Outlet />
      </main>
      <UpperFooter subscribeSectionData={subscribeSectionData} />
      <Footer
        menuList={footerMenu}
        socialLinks={socialLinks}
        copyrightContent={copyrightContent}
      />
    </div>
  );
};

export default Layout;
