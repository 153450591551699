import { Modal } from "react-bootstrap";
import { Comment } from "../utils/type";
import calculateTimeAgo from "../utils/helper";

interface CommentRepliesPopupProps {
  showCommentRepliesPopup: boolean;
  setShowCommentRepliesPopup: (value: boolean) => void;
  comment: Comment | undefined;
}

const CommentRepliesPopup = ({
  showCommentRepliesPopup,
  setShowCommentRepliesPopup,
  comment,
}: CommentRepliesPopupProps) => {
  return (
    <Modal
      show={showCommentRepliesPopup}
      onHide={() => setShowCommentRepliesPopup(false)}
      className="comment-reply-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title>Comment</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="">
          <div className="d-flex align-items-center mb-2">
            <div className="initial-circle me-2">
              {comment?.name.charAt(0).toUpperCase()}
            </div>
            <div>
              <h6 className="mb-0 text-capitalize">{comment?.name}</h6>
              <small>
                {calculateTimeAgo(comment?.created_at ?? "")}
              </small>
            </div>
          </div>
          <p className="text-capitalize">{comment?.message}</p>
        </div>
        {comment?.replies &&
          comment.replies.map((reply: any) => (
            <div key={reply.id} className="reply-item ml-4 mt-2">
              <div className="d-flex align-items-center mb-2">
                <div className="initial-circle me-2">
                  {reply.name.charAt(0).toUpperCase()}
                </div>
                <div>
                  <div className="d-flex">
                    <h6 className="mb-0 text-capitalize me-2">{reply.name}</h6>
                    <div className="tick-image">
                      <img src="/assets/imgs/other/verify-tick.png" alt="verify tick" />
                    </div>
                  </div>
                  <small>
                    {calculateTimeAgo(reply.created_at)}
                  </small>
                </div>
              </div>
              <p className="text-capitalize">{reply.message}</p>
            </div>
          ))}
      </Modal.Body>
    </Modal>
  );
};

export default CommentRepliesPopup;
